import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"auth-inner"},[_c('router-link',{staticClass:"brand-logo d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3 ",attrs:{"src":_vm.appLogo,"max-height":"120px","max-width":"120px","alt":"logo","contain":""}})],1),_c(VRow,{staticClass:"auth-row ma-0"},[_c(VCol,{staticClass:"d-none d-lg-block position-relative overflow-hidden pa-0",attrs:{"lg":"8"}},[_c('div',{staticClass:"auth-illustrator-wrapper"},[_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"362","src":require(("@/assets/images/misc/mask-v2-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}}),_c(VImg,{staticClass:"auth-tree",attrs:{"height":"226","width":"300","src":require("@/assets/images/misc/tree-4.png")}}),_c('div',{staticClass:"d-flex align-center h-full pa-16 pe-0"},[_c(VImg,{staticClass:"auth-3d-group",attrs:{"contain":"","max-width":"100%","height":"692","src":require(("@/assets/images/3d-characters/illustration-forgot-password-" + (_vm.$vuetify.theme.dark ? 'dark':'light') + ".png"))}})],1)],1)]),_c(VCol,{staticClass:"d-flex align-center auth-bg pa-10 pb-0",attrs:{"lg":"4"}},[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.message.title)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])]),_c(VCardActions,{staticClass:"d-flex justify-center align-center"},[_c('router-link',{staticClass:"d-flex align-center text-sm",attrs:{"to":{name:'auth-login'}}},[_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_c('span',[_vm._v("Zurück zum Login")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }